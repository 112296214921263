import React from 'react'
import { Layout, Container } from '../layouts'
import Hero from '../components/Global/Hero'

const PrivacyPage = () => {
  return (
    <Layout>
      <Hero title="Privacy Policy" />
      <Container>
        <p>
          <strong>TechEquipt &ndash; PRIVACY POLICY</strong>
        </p>
        <p>
          <span>
            This Privacy Policy sets out our commitment to protecting the
            privacy of personal information provided to us, or otherwise
            collected by us, offline or online, including through our{' '}
          </span>
          <span>website</span>
          <span>(</span>
          <strong>Site</strong>
          <span>). In this Privacy Policy </span>
          <strong>we</strong>
          <span>, </span>
          <strong>us</strong>
          <span> or </span>
          <strong>our</strong>
          <span> means TechEquipt - </span>
          <span>ABN 54 621 184 521.</span>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong>Personal information</strong>
        </p>
        <p>
          <span>
            The types of personal information we may collect about you include:{' '}
          </span>
        </p>
        <ul>
          <li>
            <span>your name;</span>
          </li>
          <li>
            <span>
              your contact details, including email address, mailing address,
              street address and/or telephone number;&nbsp;
            </span>
          </li>
          <li>
            <span>your age and/or date of birth;</span>
          </li>
          <li>
            <span>your demographic information, such as postcode;</span>
          </li>
          <li>
            <span>your preferences and/or opinions;</span>
          </li>
          <li>
            <span>information you provide to us through customer surveys;</span>
          </li>
          <li>
            <span>
              details of products and services we have provided to you and/or
              that you have enquired about, and our response to you;
            </span>
          </li>
          <li>
            <span>
              your browser session and geo-location data, device and network
              information, statistics on page views and sessions, acquisition
              sources, search queries and/or browsing behaviour;
            </span>
          </li>
          <li>
            <span>
              information about your access and use of our Site, including
              through the use of Internet cookies, your communications with our
              Site, the type of browser you are using, the type of operating
              system you are using and the domain name of your Internet service
              provider;
            </span>
          </li>
          <li>
            <span>
              additional personal information that you provide to us, directly
              or indirectly, through your use of our Site, associated
              applications, associated social media platforms and/or accounts
              from which you permit us to collect information; and
            </span>
          </li>
          <li>
            <span>
              any other personal information requested by us and/or provided by
              you or a third party.
            </span>
          </li>
        </ul>
        <p>
          <span>
            We may collect these types of personal information directly from you
            or from third parties.
          </span>
        </p>
        <p>
          <strong>Collection and use of personal information</strong>
        </p>
        <p>
          <span>
            We may collect, hold, use and disclose personal information for the
            following purposes:
          </span>
        </p>
        <ul>
          <li>
            <span>to contact and communicate with you;</span>
          </li>
          <li>
            <span>
              for internal record keeping and administrative purposes;
            </span>
          </li>
          <li>
            <span>
              for analytics, market research and business development, including
              to operate and improve our Site, associated applications and
              associated social media platforms;
            </span>
          </li>
          <li>
            <span>
              to run competitions and/or offer additional benefits to you;&nbsp;
            </span>
          </li>
          <li>
            <span>
              for advertising and marketing, including to send you promotional
              information about our products and services and information about
              third parties that we consider may be of interest to you;
            </span>
          </li>
          <li>
            <span>
              to comply with our legal obligations and resolve any disputes that
              we may have; and
            </span>
          </li>
          <li>
            <span>to consider your employment application.</span>
          </li>
        </ul>
        <p>
          <strong>Disclosure of personal information to third parties</strong>
        </p>
        <p>
          <span>We may disclose personal information to:&nbsp; </span>
        </p>
        <ul>
          <li>
            <span>
              third party service providers for the purpose of enabling them to
              provide their services, including (without limitation) IT service
              providers, data storage, web-hosting and server providers, debt
              collectors, maintenance or problem-solving providers, marketing or
              advertising providers, professional advisors and payment systems
              operators;
            </span>
          </li>
          <li>
            <span>our employees, contractors and/or related entities;</span>
          </li>
          <li>
            <span>sponsors or promoters of any competition we run;</span>
          </li>
          <li>
            <span>
              anyone to whom our business or assets (or any part of them) are,
              or may (in good faith) be, transferred;
            </span>
          </li>
          <li>
            <span>
              credit reporting agencies, courts, tribunals and regulatory
              authorities, in the event you fail to pay for goods or services we
              have provided to you;
            </span>
          </li>
          <li>
            <span>
              courts, tribunals, regulatory authorities and law enforcement
              officers, as required by law, in connection with any actual or
              prospective legal proceedings, or in order to establish, exercise
              or defend our legal rights; and
            </span>
          </li>
          <li>
            <span>
              third parties, including agents or sub-contractors, who assist us
              in providing information, products, services or direct marketing
              to you. This may include parties located, or that store data,
              outside of Australia;and
            </span>
          </li>
          <li>
            <span>
              third parties to collect and process data, such as Google
              Analytics or other relevant businesses. This may include parties
              that store data outside of Australia.
            </span>
          </li>
        </ul>
        <p>
          <span>
            By providing us with personal information, you consent to the
            disclosure of your personal information to third parties who reside
            outside Australia and acknowledge that we are not required to ensure
            that those third parties comply with Australian privacy laws.
          </span>
        </p>
        <p>
          <strong>
            How we treat personal information that is also sensitive information
          </strong>
        </p>
        <p>
          <span>
            Sensitive information is a subset of personal information that is
            given a higher level of protection under the Australian Privacy
            Principles.{' '}
          </span>
          <strong>Sensitive information</strong>
          <span>
            {' '}
            means information relating to your racial or ethnic origin,
            political opinions, religion, trade union or other professional
            associations or memberships, philosophical beliefs, sexual
            orientation or practices, criminal records, health information or
            biometric information.
          </span>
        </p>
        <p>
          <strong>Your rights and controlling your personal information</strong>
        </p>
        <p>
          <strong>Choice and consent</strong>
          <span>
            : Please read this Privacy Policy carefully. By providing personal
            information to us, you consent to us collecting, holding, using and
            disclosing your personal information in accordance with this Privacy
            Policy. You do not have to provide personal information to us,
            however, if you do not, it may affect your use of this Site or the
            products and/or services offered on or through it.
          </span>
        </p>
        <p>
          <strong>Information from third parties</strong>
          <span>
            : If we receive personal information about you from a third party,
            we will protect it as set out in this Privacy Policy. If you are a
            third party providing personal information about somebody else, you
            represent and warrant that you have such person&rsquo;s consent to
            provide the personal information to us.&nbsp;
          </span>
        </p>
        <p>
          <strong>Restrict</strong>
          <span>
            : You may choose to restrict the collection or use of your personal
            information.&nbsp; If you have previously agreed to us using your
            personal information for direct marketing purposes, you may change
            your mind at any time by contacting us using the details below.
          </span>
        </p>
        <p>
          <strong>Access</strong>
          <span>
            : You may request details of the personal information that we hold
            about you.&nbsp; An administrative fee may be payable for the
            provision of such information.&nbsp; In certain circumstances, as
            set out in the{' '}
          </span>
          <em>
            <span>Privacy Act 1988</span>
          </em>
          <span>
            {' '}
            (Cth), we may refuse to provide you with personal information that
            we hold about you.&nbsp;
          </span>
        </p>
        <p>
          <strong>Correction</strong>
          <span>
            : If you believe that any information we hold about you is
            inaccurate, out of date, incomplete, irrelevant or misleading,
            please contact us using the details below. We will take reasonable
            steps to correct any information found to be inaccurate, incomplete,
            misleading or out of date.
          </span>
        </p>
        <p>
          <strong>Complaints</strong>
          <span>
            : If you believe that we have breached the Australian Privacy
            Principles and wish to make a complaint, please contact us using the
            details below and provide us with full details of the alleged
            breach. We will promptly investigate your complaint and respond to
            you, in writing, setting out the outcome of our investigation and
            the steps we will take to deal with your complaint.
          </span>
        </p>
        <p>
          <strong>Unsubscribe</strong>
          <span>
            : To unsubscribe from our e-mail database or opt-out of
            communications (including marketing communications), please contact
            us using the details below or opt-out using the opt-out facilities
            provided in the communication.
          </span>
        </p>
        <p>
          <strong>Storage and security</strong>
        </p>
        <p>
          <span>
            We are committed to ensuring that the personal information we
            collect is secure. In order to prevent unauthorised access or
            disclosure, we have put in place suitable physical, electronic and
            managerial procedures to safeguard and secure the personal
            information and protect it from misuse, interference, loss and
            unauthorised access, modification and disclosure.
          </span>
        </p>
        <p>
          <span>
            We cannot guarantee the security of any information that is
            transmitted to or by us over the Internet. The transmission and
            exchange of information is carried out at your own risk. Although we
            take measures to safeguard against unauthorised disclosures of
            information, we cannot assure you that the personal information we
            collect will not be disclosed in a manner that is inconsistent with
            this Privacy Policy.
          </span>
        </p>
        <p>
          <strong>Cookies and web beacons</strong>
        </p>
        <p>
          <span>
            We may use cookies on our Site from time to time. Cookies are text
            files placed in your computer's browser to store your preferences.
            Cookies, by themselves, do not tell us your email address or other
            personally identifiable information. However, they do allow third
            parties, such as Google and Facebook, to cause our advertisements to
            appear on your social media and online media feeds as part of our
            retargeting campaigns. If and when you choose to provide our Site
            with personal information, this information may be linked to the
            data stored in the cookie.
          </span>
        </p>
        <p>
          <span>
            We may use web beacons on our Site from time to time. Web beacons
            (also known as Clear GIFs) are small pieces of code placed on a web
            page to monitor the visitor&rsquo;s behaviour and collect data about
            the visitor&rsquo;s viewing of a web page. For example, web beacons
            can be used to count the users who visit a web page or to deliver a
            cookie to the browser of a visitor viewing that page.
          </span>
        </p>
        <p>
          <strong>Links to other websites</strong>
        </p>
        <p>
          <span>
            Our Site may contain links to other websites. We do not have any
            control over those websites and we are not responsible for the
            protection and privacy of any personal information which you provide
            whilst visiting those websites. Those websites are not governed by
            this Privacy Policy.
          </span>
        </p>
        <p>
          <strong>Amendments</strong>
        </p>
        <p>
          <span>
            We may, at any time and at our discretion, vary this Privacy Policy
            by publishing the amended Privacy Policy on our Site. We recommend
            you check our Site regularly to ensure you are aware of our current
            Privacy Policy.
          </span>
        </p>
        <p>
          <strong>
            For any questions or notices, please contact our Privacy Officer at:
          </strong>
        </p>
        <p>
          <span>TechEquipt - </span>
          <span>ABN 54 621 184 521</span>
        </p>
        <p>
          <span>Email: </span>
          <span>contact@techequipt.com.au</span>
        </p>
        <p>
          <strong>Last update</strong>
          <span>: </span>
          <span>8 March 2022</span>
        </p>
      </Container>
    </Layout>
  )
}

export default PrivacyPage
